<template>
  <div class="content">
    <van-empty v-if="hasNodata" image="error" description="暂无数据" />
    <van-list
      v-else
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-cell v-for="(item, index) in list" :key="index">
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
         <div style="margin-bottom:12px">
            <span class="custom-title">{{item.deviceName}}</span>
          <van-tag style="margin-left:12px" type="danger">{{item.actionDesc}}</van-tag>
          <van-tag type="success">{{item.statusDesc}}</van-tag>
         </div>
          <span>{{ item.createAt | formatDate }}</span>
        </template>
      </van-cell>
    </van-list>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      size: 20,
      list: [],
      loading: false,
      finished: false,
      hasNodata: false,
    };
  },
  filters: {
    formatDate: function (value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
    },
  },
  created() {},
  computed: {},
  methods: {
    onLoad() {
      this.getList();
    },
    getList() {
      this.http
        .get("/gas/handsLog", {
          params: {
            companyId: localStorage.getItem("companyId"),
            page: this.page,
            size: this.size,
          },
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.loading = false;

            this.list = this.list.concat(res.data.data.list);
            if (!res.data.data.hasNextPage) {
              this.finished = true;
            } else {
              this.finished = false;
              this.page++;
            }
            if (this.page == 1 && this.list.length == 0) {
              this.hasNodata = true;
            } else {
              this.hasNodata = false;
            }
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #f2f2f2;
}
.van-list {
  height: 20%;
}
</style>
